module.exports = {
  ENV: 'prod',
  APP_NAME: 'edpm',
  END_POINT: 'https://ssm.services.apihf.com',
  API_KEY: 'XROQg6gZVH9ZDkBN309Vf6Khqmul8zW06DeQ7x1H',
  BRAND_KEY: '55429247-a78c-48ca-a1ff-cf607975676b',
  CLIENT_ID: '2hcnhauq2qob6f67q6ikcb7of1',
  USER_POOL_ID: 'eu-west-1_HIn9p5IYR',
  HEADER_MESSAGE_SIZE: 41,
  AGENTS_GROUP: 'EDPM_AGENTS',
}