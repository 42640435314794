// @flow
const  blues = {
   blue_1: '#0095d1'
};
const  reds = {
   red_1: '#FE0000'
};
export default {
   primary: reds.red_1,
};
